<template>
  <div class="quote" :class="[variant]">
    <div class="title" v-if="title">{{title}}</div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'quote',
  props: ['variant', 'title']
}
</script>

<style lang="scss" scoped>
  .quote {
    position: relative;
    border: 1px solid $hr-border-color;
    padding: 1em;
    padding-left: 2rem;
    border-radius: 4px;
    margin-bottom: 1em;
    background-color: #fff;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 8px;
      border-radius: 4px 0 0 4px;
      background-color: $text-muted;
      pointer-events: none;
    }
    &.primary:before {
      background-color: $primary;
    }
    &.success:before {
      background-color: $success;
    }
    &.warning:before {
      background-color: $warning;
    }
    &.danger:before {
      background-color: $danger;
    }
    .title {
      font-size: 1.25em;
      margin-bottom: 1em;
    }
    .content {
      ::v-deep p:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
