<template>
  <div class="card">
    <div class="card-body">
      <avatar :src="item.avatar" class="mr-2" size="2em" />
      <b>{{item.nickname}}</b>
      <span class="float-right">
        <b-badge variant="primary">坚持做书{{item.years}}年</b-badge>
        <b-badge variant="success">{{item.books}}本微信书</b-badge>
        <slot name="badge"></slot>
      </span>
    </div>
    <div class="img-box">
      <div class="img-item" v-for="(pic, i) in item.pics" :key="i">
        <square :src="pic" height="100%" img-size="640"
                    @click="$previewImage({url: pic, urls: item.pics})" />
        <!-- <slot name="swiper"></slot>
        <swiper :options="swiperOptions">
          <swiper-slide :key="i" v-for="(pic, i) in item.pics">
            <square :src="pic" height="66%" img-size="640"
                    @click="$previewImage({url: pic, urls: item.pics})" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination" v-show="item.pics.length > 1"></div>
        </swiper> -->
      </div>
    </div>
    <div class="card-body">
      <slot name="body-prepend"></slot>
      <div :class="{'expandable': canExpand, collapse}" @click="collapse = !collapse" class="text">{{item.text}}</div>
      <slot name="body-append"></slot>
      <div @click="collapse = !collapse" class="text-center text-muted mt-2" style="cursor: pointer;" v-if="canExpand">
        <fa icon="chevron-down" v-if="collapse"/>
        <fa icon="chevron-up" v-else/>
      </div>
    </div>
  </div>
</template>

<script>
import preview from '@/mixins/preview-image'

export default {
  name: 'collectionCard',
  mixins: [preview],
  props: {
    value: Object
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      collapse: false,
      canExpand: false
    }
  },
  computed: {
    item() {
      return this.value
    }
  },
  mounted() {
    this.$nextTick(() => {
      const el = this.$el.querySelector('.text')
      if (parseInt(el.scrollHeight) > 45) {
        this.collapse = true
        this.canExpand = true
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .img-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 14px;

    .img-item {
      flex: 1;
      width: calc((100% - 16px) / 3);
      min-width: calc((100% - 16px) / 3);
      max-width: calc((100% - 16px) / 3);
      margin: 0 8px 8px 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .card-img {
    position: relative;
  }

  .text {
    white-space: pre-wrap;

    &.can-collapse {
      cursor: pointer;
    }

    &.collapse {
      @include line-clamp(2);
    }
  }
</style>
