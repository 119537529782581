<template>
  <div class="collection">
    <b-modal @ok="ruleWatched = true" id="rules" ok-only ok-title="知道了" title="活动规则">
      <p>活动期间，拍一张微信书全家福，晒一下朋友圈，截图给我们就可以获得<b class="text-primary">微信书本数×10元</b>的返现奖励。</p>
      <ol>
        <li>请你找出所有做过的微信书，拍摄一张「微信书全家福」。</li>
        <li>
          将「微信书全家福」发送到自己的朋友圈，并配上主题 <br>
          <quote class="mt-3"><b>#心书让朋友圈记录一键成书#</b> 我已经坚持xx年，做了xx本微信书了</quote>
          并加上你想说的话
        </li>
        <li>将发送的朋友圈截图，并配上「微信书全家福」及其他美照上传到本页面，工作人员将会向您发放相应做书基金</li>
      </ol>
    </b-modal>
    <section id="heading">
      <div class="content">
        <div class="text-right text-primary">
          <b-link to="/library/introduction">
            <fa icon="question-circle"></fa>
            了解微信书
          </b-link>
        </div>

        <header>
          <h2 class="mb-5">
            <img draggable="false" src="../../assets/promotions/collection/title.png" style="width: 100%;">
          </h2>
          <div class="mb-5" style="font-size: 16px;">
            <p>
              心书想请你清点下回忆。一本微信书抵价10元，让我们在为你珍藏回忆的路上再多出一份力吧。
            </p>
          </div>
          <b-link v-b-modal.rules>
            <fa icon="info-circle"></fa>
            活动规则
          </b-link>
        </header>
      </div>

      <div class="arrow" @click="scrollTo(0, 600)">
        <div>下拉查看更多内容</div>
        <fa icon="angle-double-down"></fa>
      </div>
    </section>
    <section id="list">
      <c-card :value="selfItem" v-if="selfItem">
        <b-link class="float-right" slot="badge" @click="deleteSelf(selfItem.objectId)">删除</b-link>
      </c-card>
      <template v-else>
        <b-card v-if="doUpload" id="uploadForm">
          <form @submit.prevent="addItem">
            <h5>上传您的图片</h5>
            <p>最多上传 9 张图片，请至少上传一张朋友圈截图</p>
            <upload-zone min-size="320x320" v-model="newItem.pics"></upload-zone>

            <h5>这是您做微信书的第几年？</h5>
            <b-form-group>
              <b-select v-model.number="newItem.years">
                <option value="">请选择年数</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </b-select>
            </b-form-group>

            <h5>您一共做了多少本微信书？</h5>
            <b-form-group>
              <b-input-group append="本">
                <input class="form-control" placeholder="请输入数量" type="number" v-model.number="newItem.books"/>
              </b-input-group>
            </b-form-group>

            <h5>分享您的故事</h5>
            <b-form-group>
              <textarea class="form-control" placeholder="点击这里开始分享您的故事..." rows="3"
                        v-autosize v-model="newItem.text"></textarea>
            </b-form-group>

            <b-btn :disabled="!canSubmit || submitting" block type="submit" variant="primary">提交</b-btn>
            <b-btn variant="link" @click="doUpload = false" block>取消</b-btn>
          </form>
        </b-card>

        <b-card @click="doUpload = !doUpload" class="text-primary text-center" style="cursor: pointer;" v-else>
          <fa icon="camera"></fa>
          上传图片
        </b-card>
      </template>

      <loading v-if="loading"/>
      <template v-else>
        <c-card :key="item.objectId" :value="item" v-for="(item, i) in items">
          <template slot="swiper">
            <div @click="hideGuide = true" @touchstart="hideGuide = true" class="guide" v-if="!hideGuide && i === 0">
              <img alt="左右滑动查看图片" draggable="false" src="../../assets/promotions/collection/swipe.png">
            </div>
          </template>
        </c-card>
      </template>
    </section>
  </div>
</template>

<script>
import CCard from './CollectionCard'
import Quote from '../../components/Quote'
import UploadZone from '../../components/UploadZone'

import lean from '../../models/leancloud'

export default {
  name: 'collection',
  components: { CCard, UploadZone, Quote },
  watch: {
    newItem: {
      handler(val) {
        this.$ls.set('collection.newItem', val)
      },
      deep: true
    },
    hideGuide(val) {
      return this.$ls[val ? 'set' : 'remove']('collection.guide', 1)
    },
    ruleWatched(val) {
      return this.$ls[val ? 'set' : 'remove']('collection.rule', 1)
    },
    doUpload(val) {
      if (val) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('#uploadForm')
          el.scrollIntoViewIfNeeded ? el.scrollIntoViewIfNeeded() : el.scrollIntoView()
        })
      }
    }
  },
  data() {
    return {
      loading: false,
      submitting: false,
      doUpload: false,
      ruleWatched: this.$ls.get('collection.rule') || false,
      selfItem: null,
      hideGuide: this.$ls.get('collection.guide'),
      newItem: this.$ls.getJSON('collection.newItem') || {
        years: '',
        books: '',
        text: '',
        pics: []
      },
      items: []
    }
  },
  computed: {
    canSubmit() {
      return this.newItem.pics.length && this.newItem.text && this.newItem.years && this.newItem.books
    }
  },
  async created() {
    this.loading = true
    this.items = await this.fetchItems()
    await this.fetchSelf().then(result => {
      this.selfItem = result[0]
    })
    this.items = this.items.filter(item => item.openid !== this.user.openid)
    this.loading = false
    if (!this.ruleWatched) {
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'rules')
      })
    }
  },
  methods: {
    scrollTo(x, y) {
      window.scrollTo(x, y)
    },
    fetchItems() {
      return lean.get('/classes/Collection?order=-createdAt&limit=1000')
    },
    fetchSelf() {
      return lean.get('/classes/Collection?where={"openid": "' + this.user.openid + '"}&order=-createdAt&limit=1')
    },
    async deleteSelf(objectId) {
      const confirmed = await this.$dialog.confirm({
        title: '删除晒单',
        content: '是否要删除自己的晒单？'
      })
      if (!confirmed) {
        return
      }
      return lean.delete('/classes/Collection/' + objectId).then(() => {
        this.$toasted.show('删除成功')
        this.selfItem = null
      })
    },
    addItem() {
      const data = Object.assign({
        avatar: this.user.avatar,
        openid: this.user.openid,
        nickname: this.user.nickname
      }, this.newItem)
      data.pics = data.pics.map(i => i.url)
      return lean.post('/classes/Collection', data).then(() => {
        this.selfItem = data
        this.$toasted.success('发布成功')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .collection {
    padding: 0 !important;
    max-width: 560px;

    ol {
      padding-left: 1.25em;

      li {
        margin-bottom: .5em;
      }
    }

    .guide {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      display: flex;
      object-fit: contain;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, .5);

      img {
        max-width: 80%;
      }
    }

    .arrow {
      padding: 1rem;
      position: absolute;
      width: 12em;
      margin-left: auto;
      margin-right: auto;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      color: #40526C;
      text-shadow: 0 0 10px rgba(0, 0, 0, .1);

      .fa {
        animation: upDown .8s infinite;
      }
    }

    #writer {
      padding: 1.5rem;
    }

    section {
      position: relative;
    }

    .card {
      box-shadow: 0 10px 16px rgba(0, 0, 0, 0.1);
      border: none;
      border-radius: 10px;
    }

    #heading {
      border-radius: 10px;
      min-height: 100vh;
      max-height: 100%;
      background-image: url(../../assets/promotions/collection/bg-1.jpg);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: cover;
    }

    #heading .content {
      position: absolute;
      padding: 2rem;
      top: 0;
      left: 0;
      right: 0;
    }

    #list {
      padding: 1.5rem;

      .card:only-child {
        margin-bottom: 0;
      }
    }

    .writer {
      border: 0;
      background-color: transparent;
      box-shadow: none;
      resize: none;
      width: 100%;
      display: block;
      outline: none;
      padding: 0;
    }
  }
</style>

<style lang="scss">
  body[data-page="collection"] {
    background-image: url(../../assets/promotions/collection/bg-2.jpg);
    background-size: auto 100%;
    background-attachment: fixed;
    background-repeat: repeat-x;

    main {
      padding-bottom: 0;
    }
  }
</style>
